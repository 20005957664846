import { Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Register from './pages/auth/register';
import Login from './pages/auth/login';
import './App.css';

function App() {
  return (
    <Router>
      <Box boxSizing='border-box'>
        <Routes>
          <Route path='/register' element={<Register />} />
          <Route path='/' element={<Login />} />
        </Routes>
      </Box>
    </Router>
  );
}

export default App;
