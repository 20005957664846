import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { Visibility, VisibilityOff, HelpRounded } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../../assets/logo.png';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
    },
    background: {
      default: '#002B5C',
    },
  },
});

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [countryCode, setCountryCode] = useState('+61');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleGetCode = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        minHeight='100vh'
        bgcolor='background.default'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <Container component='main' maxWidth='xs'>
          <Box display='flex' flexDirection='column'>
            <img src={Logo} alt='Lotworks Logo' style={{ width: 200, marginBottom: 40 }} />
            <Typography variant='subtitle1' color='white'>Register</Typography>
            <Box component='form' onSubmit={handleSubmit}>
              <TextField
                required
                fullWidth
                id='email'
                placeholder='Email'
                name='email'
                autoComplete='email'
                autoFocus
                sx={{ bgcolor: 'white', borderRadius: 1, mt: 2 }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Tooltip
                title='Password must include 6 digits at least, case sensitive, number...'
                placement='right-end'
                arrow
                slotProps={{
                  tooltip: {
                    sx: {
                      width: 160,
                      bgcolor: 'white',
                      fontSize: 14,
                      color: '#333'
                    }
                  },
                  arrow: {
                    sx: {
                      color: 'white',
                    }
                  }
                }}
              >
                <TextField
                  required
                  fullWidth
                  variant='outlined'
                  id='password'
                  placeholder='Create password'
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  autoComplete='new-password'
                  sx={{ bgcolor: 'white', borderRadius: 1, mt: 2 }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => setShowPassword(!showPassword)}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Tooltip>
              <TextField
                required
                fullWidth
                id='confirmPassword'
                placeholder='Confirm password'
                name='confirmPassword'
                type='password'
                autoComplete='new-password'
                sx={{ bgcolor: 'white', borderRadius: 1, mt: 2 }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Box mt={2} display='flex' gap={1}>
                <FormControl sx={{ minWidth: 100 }}>
                  <Select
                    id='country-code'
                    labelId='country-code-label'
                    sx={{ bgcolor: 'white', borderRadius: 1 }}
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                  >
                    <MenuItem value='+61'>+61</MenuItem>
                    <MenuItem value='+1'>+1</MenuItem>
                    <MenuItem value='+44'>+44</MenuItem>
                    <MenuItem value='+86'>+86</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  required
                  fullWidth
                  id='phoneNumber'
                  placeholder='Phone number'
                  name='phoneNumber'
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  sx={{ bgcolor: 'white', borderRadius: 1 }}
                />
              </Box>
              <Box mt={2} display='flex' gap={1}>
                <TextField
                  required
                  fullWidth
                  id='verificationCode'
                  placeholder='Verification code'
                  name='verificationCode'
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  sx={{ bgcolor: 'white', borderRadius: 1 }}
                />
                <Button
                  variant='outlined'
                  sx={{ bgcolor: 'white', whiteSpace: 'nowrap', color: '#333', textTransform: 'none' }}
                  text='off'
                  disabled={loading}
                  onClick={handleGetCode}
                >
                  {loading ? 'Sending...' : 'Get Code'}
                </Button>
              </Box>
              <Box mt={2} display='flex' alignItems='center' justifyContent='space-between'>
                <Button variant='contained' type='submit' sx={{ width: '50%' }}>
                  Register
                </Button>
                <Button variant='text' sx={{ color: 'primary.light', textTransform: 'none' }}>
                  Login
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
        <Tooltip title='Need help?' arrow>
          <IconButton
            sx={{
              position: 'fixed',
              bottom: 20,
              right: 40,
              color: 'white',
            }}
          >
            <HelpRounded sx={{ fontSize: 32 }} />
          </IconButton>
        </Tooltip>
      </Box>
    </ThemeProvider>
  );
}

export default Register;
