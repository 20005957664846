import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Visibility, VisibilityOff, HelpRounded } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import Logo from '../../assets/logo.png';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
    },
    background: {
      default: '#002B5C',
    },
  },
});

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (data) => {
    console.log('Form submitted:', data);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        minHeight='100vh'
        bgcolor='background.default'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <Container component='main' maxWidth='xs'>
          <Box display='flex' flexDirection='column'>
            <img src={Logo} alt='Lotworks Logo' style={{ width: 200, marginBottom: 40 }} />
            <Typography variant='subtitle1' color='white'>Login</Typography>
            <Box component='form' onSubmit={handleSubmit(onSubmit)}>
              <TextField
                fullWidth
                variant='outlined'
                id='email'
                placeholder='Email'
                name='email'
                autoComplete='email'
                autoFocus
                sx={{ bgcolor: 'white', borderRadius: 1, mt: 2 }}
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Invalid email address',
                  },
                })}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
              <Tooltip
                title='Password must include at least 6 characters, case sensitive, with numbers...'
                placement='right-end'
                arrow
                slotProps={{
                  tooltip: {
                    sx: {
                      width: 160,
                      bgcolor: 'white',
                      fontSize: 14,
                      color: '#333'
                    }
                  },
                  arrow: {
                    sx: {
                      color: 'white',
                    }
                  }
                }}
              >
                <TextField
                  fullWidth
                  variant='outlined'
                  id='password'
                  placeholder='Create password'
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  autoComplete='new-password'
                  sx={{ bgcolor: 'white', borderRadius: 1, mt: 2 }}
                  {...register('password', {
                    required: 'Password is required',
                    minLength: {
                      value: 6,
                      message: 'Password must be at least 6 characters long',
                    },
                  })}
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => setShowPassword(!showPassword)}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Tooltip>
              <Box mt={2} display='flex' alignItems='center' justifyContent='space-between'>
                <Button variant='contained' type='submit' sx={{ width: '50%' }}>
                  Login
                </Button>
                <Button variant='text' sx={{ color: 'primary.light', textTransform: 'none' }}>
                  Forgot password
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
        <Tooltip title='Need help?' arrow>
          <IconButton
            sx={{
              position: 'fixed',
              bottom: 20,
              right: 40,
              color: 'white',
            }}
          >
            <HelpRounded sx={{ fontSize: 32 }} />
          </IconButton>
        </Tooltip>
      </Box>
    </ThemeProvider>
  );
}

export default Login;
